.MuiInputBase-root {
  border-radius: 0.5rem !important;
  min-height: 2.75rem !important;
  margin-top: 0.25rem !important;
}

.MuiOutlinedInput-root {
  padding: 0 .3rem !important;
  padding-right: 3.33rem !important;
}

.MuiChip-root {
  height: 1.7rem !important;
  margin: .5rem .3rem !important;
}

.MuiChip-root span {
    margin-top: 3px;
}
.MuiOutlinedInput-input::placeholder {
  font-size: 0.8rem !important;
}

[type="text"]:focus {
  --tw-ring-color: none !important;
}
.MuiAutocomplete-inputFocused * {
    border: none !important
}