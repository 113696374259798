.H_ib_body {
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 0;
}
.H_ib_content {
  cursor: pointer;
  min-width: 6em;
  margin: 0;
  font: 12px "Lucida Grande", Arial, Helvetica, sans-serif;
  line-height: 24px;
  color: rgba(15, 22, 33, 0.8);
}
.H_ib.H_ib_top .H_ib_tail {
  margin-bottom: 18px;
  transition: all 200ms ease-in;
}
.H_ib.H_ib_top .H_ib_tail::after {
  margin-bottom: 18px;
}
.H_ib.H_ib_top .H_ib_tail::before {
  display: none;
}

.H_ib_close {
  font-size: 0.6em;
  position: absolute;
  right: 12px;
  width: 8px;
  height:8px;
  cursor: pointer;
  top: 12px;
  z-index: 1;
  background: none;
  box-shadow: none;
}
.H_ib_content div {
  padding: 10px 20px;
  min-width: 7.5em;
}
.H_ib_content span {
  color: #008489;
  font-size: 0.9em;
}
.H_ib_content section {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.H_ib_content section p {
  line-height: 20px;
  margin-top: -2px;
  padding-right: 15px;
}
.H_ib_content section img {
  margin-right: 5px;
}
