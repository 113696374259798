.text-editor {
  border-width: 0.5px;
  border-color: #aaa4;
  margin-top: 5px;
  width: 100%;
  max-width: 80vw;
}
.ql-toolbar,
.ql-snow {
  border: none !important;
}
.ql-toolbar {
  border-bottom: 1px solid #ccc3 !important;
}
.ql-editor,
.ql-blank::before {
  min-height: 10rem;
  color: inherit !important;
}
.dark .ql-snow .ql-stroke {
    stroke: #bbb !important;
}
.dark .ql-fill{
    fill: #bbb !important;
}
.dark .ql-picker-label {
    color: #bbb !important;
}

.dark .ql-snow .ql-picker-options{
    border-color: #ccc3 !important;
    background-color: #101020 !important;
    color: #ccc !important;
}