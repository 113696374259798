@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap');

// // FONT FACE --INTER
// @font-face {
//   font-family: Inter;
//   font-weight: 300;
//   src: url("../fonts/Inter/static/Inter-Light.ttf");
// }
// @font-face {
//   font-family: Inter;
//   font-weight: 400;
//   src: url("../fonts/Inter/static/Inter-Regular.ttf");
// }
// @font-face {
//   font-family: Inter;
//   font-weight: 500;
//   src: url("../fonts/Inter/static/Inter-Medium.ttf");
// }
// @font-face {
//   font-family: Inter;
//   font-weight: 600;
//   src: url("../fonts/Inter/static/Inter-SemiBold.ttf");
// }
// @font-face {
//   font-family: Inter;
//   font-weight: 700;
//   src: url("../fonts/Inter/static/Inter-Bold.ttf");
// }

// // POPPINS
// @font-face {
//   font-family: Poppins;
//   font-weight: 300;
//   src: url("../fonts/Poppins/Poppins-Light.ttf");
// }
// @font-face {
//   font-family: Poppins;
//   font-weight: 400;
//   src: url("../fonts/Poppins/Poppins-Regular.ttf");
// }
// @font-face {
//   font-family: Poppins;
//   font-weight: 500;
//   src: url("../fonts/Poppins/Poppins-Medium.ttf");
// }
// @font-face {
//   font-family: Poppins;
//   font-weight: 600;
//   src: url("../fonts/Poppins/Poppins-SemiBold.ttf");
// }
// @font-face {
//   font-family: Poppins;
//   font-weight: 700;
//   src: url("../fonts/Poppins/Poppins-Bold.ttf");
// }

// //
// // FONT FACE --MerriweatherSans
// @font-face {
//   font-family: MerriweatherSans;
//   font-weight: 300;
//   src: url("../fonts/Merriweather_Sans/static/MerriweatherSans-Light.ttf");
// }
// @font-face {
//   font-family: MerriweatherSans;
//   font-weight: 400;
//   src: url("../fonts/Merriweather_Sans/static/MerriweatherSans-Regular.ttf");
// }
// @font-face {
//   font-family: MerriweatherSans;
//   font-weight: 500;
//   src: url("../fonts/Merriweather_Sans/static/MerriweatherSans-Medium.ttf");
// }
// @font-face {
//   font-family: MerriweatherSans;
//   font-weight: 600;
//   src: url("../fonts/Merriweather_Sans/static/MerriweatherSans-SemiBold.ttf");
// }
// @font-face {
//   font-family: MerriweatherSans;
//   font-weight: 700;
//   src: url("../fonts/Merriweather_Sans/static/MerriweatherSans-Bold.ttf");
// }
// //
// // FONT FACE --Kodchasan
// @font-face {
//   font-family: Kodchasan;
//   font-weight: 300;
//   src: url("../fonts/Kodchasan/Kodchasan-Light.ttf");
// }
// @font-face {
//   font-family: Kodchasan;
//   font-weight: 400;
//   src: url("../fonts/Kodchasan/Kodchasan-Regular.ttf");
// }
// @font-face {
//   font-family: Kodchasan;
//   font-weight: 500;
//   src: url("../fonts/Kodchasan/Kodchasan-Medium.ttf");
// }
// @font-face {
//   font-family: Kodchasan;
//   font-weight: 600;
//   src: url("../fonts/Kodchasan/Kodchasan-SemiBold.ttf");
// }
// @font-face {
//   font-family: Kodchasan;
//   font-weight: 700;
//   src: url("../fonts/Kodchasan/Kodchasan-Bold.ttf");
// }

// // FONT FACE --BeVietnam
// @font-face {
//   font-family: BeVietnam;
//   font-weight: 300;
//   src: url("../fonts/Be_Vietnam/BeVietnam-Light.ttf");
// }
// @font-face {
//   font-family: BeVietnam;
//   font-weight: 400;
//   src: url("../fonts/Be_Vietnam/BeVietnam-Regular.ttf");
// }
// @font-face {
//   font-family: BeVietnam;
//   font-weight: 500;
//   src: url("../fonts/Be_Vietnam/BeVietnam-Medium.ttf");
// }
// @font-face {
//   font-family: BeVietnam;
//   font-weight: 600;
//   src: url("../fonts/Be_Vietnam/BeVietnam-SemiBold.ttf");
// }
// @font-face {
//   font-family: BeVietnam;
//   font-weight: 700;
//   src: url("../fonts/Be_Vietnam/BeVietnam-Bold.ttf");
// }

//
html body {
  @apply font-body antialiased;
}

h1,
h2,
h3,
h4 {
  @apply font-display;
}

:root {
  --font-display: 'Lato', sans-serif;;
  --font-body: 'Lato', sans-serif;;
}

.theme-animals {
  --font-display: 'Lato', sans-serif;;
  --font-body: 'Lato', sans-serif;;
}

.theme-astronomy {
  --font-display: 'Lato', sans-serif;;
  --font-body: 'Lato', sans-serif;;
}
